import React from 'react'

const PatientDashboard = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <h1 className="text-3xl font-bold mb-4">Hello, user</h1>
      <div className='grid gap-4 lg:grid-rows-3'>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 " >
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Appointments</h2>
            <p>Upcoming: 3</p>
            <p>Past: 15</p>
          </div>
          <div className="col-span-2 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Medical History</h2>
            <p>Records Updated: Yes</p>
            <p>Last Visit: Oct 10, 2024</p>
          </div>
        </div>
        <div className='grid gap-4 grid-cols-4'>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Prescriptions</h2>
            <p>Total Prescriptions: 5</p>
            <p>Last Issued: Oct 12, 2024</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Patient Records</h2>
            <p>Total Patients: 50</p>
            <p>New Patients: 2</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Billing</h2>
            <p>Outstanding: ₹1,500</p>
            <p>Settled: ₹10,000</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Notifications</h2>
            <p>Pending Reminders: 1</p>
            <p>New Messages: 3</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientDashboard
