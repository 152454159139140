import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

// Appointment Card Component
const AppointmentCard = ({
  item,
  handleInputChange,
  handleUpdate,
  handleDelete,
  loadingUpdates,
}) => {
  const [localNotes, setLocalNotes] = useState(item.notes || '');
  const [localStatus, setLocalStatus] = useState(item.status);
  const [localDate, setLocalDate] = useState(
    new Date(item.date).toISOString().split('T')[0]
  );
  const [localTimeBlock, setLocalTimeBlock] = useState(item.timeBlock);
  const [localReason, setLocalReason] = useState(item.reason);
  const [localName, setLocalName] = useState(item.name || ''); // Assuming 'name' field exists

  // Handle local input changes and propagate to parent
  const onChange = (field, value) => {
    handleInputChange(item._id, field, value);
    // Update local state for immediate UI feedback
    switch (field) {
      case 'notes':
        setLocalNotes(value);
        break;
      case 'status':
        setLocalStatus(value);
        break;
      case 'date':
        setLocalDate(value);
        break;
      case 'timeBlock':
        setLocalTimeBlock(value);
        break;
      case 'reason':
        setLocalReason(value);
        break;
      case 'name':
        setLocalName(value);
        break;
      default:
        break;
    }
  };

  return (
    <motion.div
      className="border rounded-lg p-6 bg-white shadow-md flex flex-col"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <h2 className="text-xl font-semibold mb-4">{item.name}</h2>

      

      {/* Date Field */}
      <div className="mb-3">
        <label htmlFor={`date-${item._id}`} className="block text-sm font-medium text-gray-700">
          Date:
        </label>
        <input
          type="date"
          id={`date-${item._id}`}
          value={localDate}
          onChange={(e) => onChange('date', e.target.value)}
          className="mt-1 block w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Time Block Field */}
      <div className="mb-3">
        <label htmlFor={`timeBlock-${item._id}`} className="block text-sm font-medium text-gray-700">
          Time Block:
        </label>
        <select
          id={`timeBlock-${item._id}`}
          value={localTimeBlock}
          onChange={(e) => onChange('timeBlock', e.target.value)}
          className="mt-1 block w-full border rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {[
            '10:00 AM - 12:00 PM',
            '12:00 PM - 2:00 PM',
            '2:00 PM - 4:00 PM',
            '4:00 PM - 6:00 PM',
            '6:00 PM - 8:00 PM',
          ].map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>

      {/* Reason Field */}
      <div className="mb-3">
        <label htmlFor={`reason-${item._id}`} className="block text-sm font-medium text-gray-700">
          Reason:
        </label>
        <select
          id={`reason-${item._id}`}
          value={localReason}
          onChange={(e) => onChange('reason', e.target.value)}
          className="mt-1 block w-full border rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {[
            'Routine Check-Up',
            'Cleaning',
            'Fillings',
            'Extraction',
            'Orthodontics',
            'Other',
          ].map((reason) => (
            <option key={reason} value={reason}>
              {reason}
            </option>
          ))}
        </select>
      </div>

      {/* Status Field */}
      <div className="mb-3">
        <label htmlFor={`status-${item._id}`} className="block text-sm font-medium text-gray-700">
          Status:
        </label>
        <select
          id={`status-${item._id}`}
          value={localStatus}
          onChange={(e) => onChange('status', e.target.value)}
          className="mt-1 block w-full border rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {['Pending', 'Scheduled', 'Completed', 'Cancelled', 'No Show'].map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>

      {/* Notes Field */}
      <div className="mb-4">
        <label htmlFor={`notes-${item._id}`} className="block text-sm font-medium text-gray-700">
          Notes:
        </label>
        <input
          type="text"
          id={`notes-${item._id}`}
          value={localNotes}
          onChange={(e) => onChange('notes', e.target.value)}
          className="mt-1 block w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter notes"
        />
      </div>

      {/* Action Buttons */}
      <div className="mt-auto flex space-x-2">
        <button
          onClick={() => handleUpdate(item._id)}
          className={`flex-1 px-4 py-2 rounded-md text-white ${
            loadingUpdates[item._id] ? 'bg-green-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
          } transition-colors duration-200`}
          disabled={loadingUpdates[item._id]}
        >
          {loadingUpdates[item._id] ? 'Updating...' : 'Update'}
        </button>
        <button
          onClick={() => handleDelete(item._id)}
          className="flex-1 px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 transition-colors duration-200"
        >
          Delete
        </button>
        <button
          onClick={() => {
            if (item.mobile) {
              window.location.href = `tel:${item.mobile}`;
            } else {
              alert('Mobile number is not available.');
            }
          }}
          className={`flex-1 px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200 ${
            !item.mobile ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={!item.mobile}
        >
          Call
        </button>
      </div>
    </motion.div>
  );
};

// Main Component
const AllAppointment = () => {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState('');
  const [pendingUpdates, setPendingUpdates] = useState({});
  const [loadingUpdates, setLoadingUpdates] = useState({}); // Loader state for each appointment
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Filter States
  const [dateFilter, setDateFilter] = useState('all'); // 'today', 'upcoming', 'past', 'all'
  const [statusFilter, setStatusFilter] = useState('all'); // 'Scheduled', 'Pending', 'Completed', 'Cancelled', 'all'
  const [sortOption, setSortOption] = useState('none'); // 'name', 'date', 'none'

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/receptionist/appointment`);
      setAppointments(response.data);
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || 'Failed to fetch appointments');
      alert(error.response?.data?.message || 'Failed to fetch appointments');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (id, field, value) => {
    setPendingUpdates((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleUpdate = async (id) => {
    const updates = pendingUpdates[id];
    if (!updates) {
      alert('No changes to update.');
      return;
    }

    setLoadingUpdates((prev) => ({ ...prev, [id]: true }));

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/receptionist/appointment/${id}`, updates);
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment._id === id ? { ...appointment, ...updates } : appointment
        )
      );

      setPendingUpdates((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
      alert('Appointment updated successfully!');
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || 'Failed to update appointment');
      alert(error.response?.data?.message || 'Failed to update appointment');
    } finally {
      setLoadingUpdates((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this appointment?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/receptionist/appointment/${id}`);
      setAppointments((prevAppointments) =>
        prevAppointments.filter((appointment) => appointment._id !== id)
      );
      alert('Appointment deleted successfully!');
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || 'Failed to delete appointment');
      alert(error.response?.data?.message || 'Failed to delete appointment');
    }
  };

  // Filtering and Sorting Logic Without External Libraries
  const filteredAppointments = useMemo(() => {
    const today = new Date();
    // Reset time to midnight for accurate comparison
    today.setHours(0, 0, 0, 0);

    let filtered = appointments.filter((appointment) => {
      let dateCondition = true;
      let statusCondition = true;

      // Date Filter
      if (dateFilter !== 'all') {
        const appointmentDate = new Date(appointment.date);
        appointmentDate.setHours(0, 0, 0, 0);

        if (dateFilter === 'today') {
          dateCondition = appointmentDate.getTime() === today.getTime();
        } else if (dateFilter === 'upcoming') {
          dateCondition = appointmentDate.getTime() > today.getTime();
        } else if (dateFilter === 'past') {
          dateCondition = appointmentDate.getTime() < today.getTime();
        }
      }

      // Status Filter
      if (statusFilter !== 'all') {
        statusCondition = appointment.status.toLowerCase() === statusFilter.toLowerCase();
      }

      return dateCondition && statusCondition;
    });

    // Sorting Logic
    if (sortOption !== 'none') {
      filtered.sort((a, b) => {
        if (sortOption === 'name') {
          const nameA = a.name ? a.name.toLowerCase() : '';
          const nameB = b.name ? b.name.toLowerCase() : '';
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        } else if (sortOption === 'date') {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB; // Ascending order
        }
        return 0;
      });
    }

    return filtered;
  }, [appointments, dateFilter, statusFilter, sortOption]);

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const totalPages = useMemo(() => Math.ceil(filteredAppointments.length / itemsPerPage), [filteredAppointments.length, itemsPerPage]);

  const paginatedAppointments = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return filteredAppointments.slice(start, start + itemsPerPage);
  }, [filteredAppointments, currentPage, itemsPerPage]);

  const pageNumbers = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }, [totalPages]);

  // Reset to first page when filters or sort change
  useEffect(() => {
    setCurrentPage(1);
  }, [dateFilter, statusFilter, sortOption]);

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">All Appointments</h1>

      {/* Error Message */}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Filters and Sort */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
        {/* Date Filter */}
        <div className="flex items-center space-x-2">
          <label htmlFor="dateFilter" className="font-medium">
            Date:
          </label>
          <select
            id="dateFilter"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All</option>
            <option value="today">Today</option>
            <option value="upcoming">Upcoming</option>
            <option value="past">Past</option>
          </select>
        </div>

        {/* Status Filter */}
        <div className="flex items-center space-x-2">
          <label htmlFor="statusFilter" className="font-medium">
            Status:
          </label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All</option>
            <option value="Scheduled">Scheduled</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Canceled">Canceled</option>
          </select>
        </div>

        {/* Sort Filter */}
        <div className="flex items-center space-x-2">
          <label htmlFor="sortOption" className="font-medium">
            Sort By:
          </label>
          <select
            id="sortOption"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="none">None</option>
            <option value="name">Name</option>
            <option value="date">Date</option>
          </select>
        </div>

        {/* Clear Filters and Sort Button */}
        <button
          onClick={() => {
            setDateFilter('all');
            setStatusFilter('all');
            setSortOption('none');
          }}
          className="mt-2 sm:mt-0 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors duration-200"
        >
          Clear Filters
        </button>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <p>Loading appointments...</p>
      ) : (
        <>
          {/* Responsive Grid of Appointment Cards */}
          {filteredAppointments.length > 0 ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <AnimatePresence>
                  {paginatedAppointments.map((item) => (
                    <AppointmentCard
                      key={item._id}
                      item={item}
                      handleInputChange={handleInputChange}
                      handleUpdate={handleUpdate}
                      handleDelete={handleDelete}
                      loadingUpdates={loadingUpdates}
                    />
                  ))}
                </AnimatePresence>
              </div>

              {/* Pagination Controls */}
              <div className="flex flex-col sm:flex-row justify-between items-center mt-8">
                {/* Items Per Page Selector */}
                <div className="mb-4 sm:mb-0">
                  <label htmlFor="itemsPerPage" className="mr-2 font-medium">
                    Items per Page:
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(parseInt(e.target.value));
                      setCurrentPage(1); // Reset to first page when items per page changes
                    }}
                    className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>

                {/* Page Numbers */}
                <div className="flex space-x-2">
                  <AnimatePresence>
                    {pageNumbers.map((number) => (
                      <motion.button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`px-4 py-2 rounded-md text-sm font-medium
                          ${
                            currentPage === number
                              ? 'bg-blue-600 text-white'
                              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                          }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {number}
                      </motion.button>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            </>
          ) : (
            <p>No appointments match the selected filters.</p>
          )}
        </>
      )}
    </div>
  );
};

export default AllAppointment;
