import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';

const MedicalProfile = () => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    bloodGroup: '',
    medicalHistory: '',
    medications: [{ name: '', dosage: '', frequency: '', startDate: '', endDate: '' }],
    allergies: [{ allergen: '', reaction: '', severity: '' }],
    lifestyleAndHabits: { smokingStatus: '', alcoholUse: '', tobacco: '' },
    pastDentalHistory: '',
    notes: '',
  });
  const [isModified, setIsModified] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    // Fetch existing medical details from the API and populate the form
    const fetchMedicalDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/medical-details/${user.medicalDetails}`
        );
        setFormData(response.data);
      } catch (error) {
        setError('Failed to fetch medical details');
      }
    };
    fetchMedicalDetails();
  }, [user.medicalDetails]);

  // Update `isModified` when the form data changes
  const handleFormChange = () => {
    setIsModified(true);
  };

  // Handle changes for top-level properties
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    handleFormChange();
  };

  // Handle changes for nested arrays (e.g., medications, allergies)
  const handleNestedChange = (e, section, index, field) => {
    const updatedSection = formData[section].map((item, idx) =>
      idx === index ? { ...item, [field]: e.target.value } : item
    );
    setFormData({ ...formData, [section]: updatedSection });
    handleFormChange();
  };

  // Handle changes for nested objects (e.g., lifestyleAndHabits)
  const handleNestedPropertyChange = (e, section, field) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [field]: e.target.value,
      },
    });
    handleFormChange();
  };

  // Function to add more allergies
  const addAllergy = () => {
    setFormData({
      ...formData,
      allergies: [...formData.allergies, { allergen: '', reaction: '', severity: '' }],
    });
    handleFormChange();
  };

  // Function to add more medications
  const addMedication = () => {
    setFormData({
      ...formData,
      medications: [
        ...formData.medications,
        { name: '', dosage: '', frequency: '', startDate: '', endDate: '' },
      ],
    });
    handleFormChange();
  };
  const removeMedication = (index) => {
    setFormData({
      ...formData,
      medications: formData.medications.filter((_, idx) => idx !== index),
    });
    handleFormChange();
  };

  const removeAllergy = (index) => {
    setFormData({
      ...formData,
      allergies: formData.allergies.filter((_, idx) => idx !== index),
    });
    handleFormChange();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/medical-details/${user.medicalDetails}`,
        formData
      );
      setSuccess('Medical details updated successfully');
      setError('');
      setIsModified(false); // Reset `isModified` after successful submission
    } catch (err) {
      setError('Failed to update medical details');
      setSuccess('');
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mx-auto mt-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Medical Details</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        {/* Medical Details */}
        <section className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Blood Group */}
          <div className='col-span-2 sm:col-span-1'>
            <label className="block text-gray-600 mb-1">Blood Group:</label>
            <select
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>
                Select Blood Group
              </option>
              {['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>
         
          <div className='col-span-2 sm:col-span-1'>
            <label className="block text-gray-600 mb-1">Medical History:</label>
            <textarea
              name="medicalHistory"
              value={formData.medicalHistory}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
         
          {/* Medications */}
          <h3 className="col-span-2 text-lg font-semibold">Medications:</h3>
          {formData.medications.map((med, idx) => (
            <div key={idx} className="col-span-2 grid grid-cols-10 gap-2">
              <input
                placeholder="Name"
                value={med.name}
                onChange={(e) => handleNestedChange(e, 'medications', idx, 'name')}
                className="p-2 border border-gray-300 rounded-md col-span-10 sm:col-span-3"
              />
              <input
                placeholder="Dosage"
                value={med.dosage}
                onChange={(e) => handleNestedChange(e, 'medications', idx, 'dosage')}
                className="p-2 border border-gray-300 rounded-md col-span-10 sm:col-span-3"
              />
              <input
                placeholder="Frequency"
                value={med.frequency}
                onChange={(e) => handleNestedChange(e, 'medications', idx, 'frequency')}
                className="p-2 border border-gray-300 rounded-md col-span-9 sm:col-span-3"
              />
              <button
                  type="button"
                  onClick={() => removeMedication(idx)}
                  className="text-red-500 hover:text-red-700"
                >
                  &#10005; {/* Cross icon */}
                </button>
            </div>
          ))}
          {/* <div className='grid grid-cols-1 sm:grid-cols-2'> */}
          <button type="button" onClick={addMedication} className="mt-2 px-4 py-2 bg-green-500 text-white rounded-lg col-span-2 sm:w-[25%]">
            Add Medication
          </button>
          {/* </div> */}
          {/* Allergies */}
          <h3 className="col-span-2 text-lg font-semibold">Allergies:</h3>
          {formData.allergies.map((allergy, idx) => (
            <div key={idx} className="col-span-2 grid grid-cols-10 gap-2">
              <input
                placeholder="Allergen"
                value={allergy.allergen}
                onChange={(e) => handleNestedChange(e, 'allergies', idx, 'allergen')}
                className="p-2 border border-gray-300 rounded-md col-span-10 sm:col-span-3"
              />
              <input
                placeholder="Reaction"
                value={allergy.reaction}
                onChange={(e) => handleNestedChange(e, 'allergies', idx, 'reaction')}
                className="p-2 border border-gray-300 rounded-md col-span-10 sm:col-span-3"
              />
              <select
                value={allergy.severity}
                onChange={(e) => handleNestedChange(e, 'allergies', idx, 'severity')}
                className="p-2 border border-gray-300 rounded-md col-span-9 sm:col-span-3"
              >
                <option value="" disabled>Select Severity</option>
                {['Mild', 'Moderate', 'Severe'].map((severity) => (
                  <option key={severity} value={severity}>{severity}</option>
                ))}
              </select>
              <button
                  type="button"
                  onClick={() => removeAllergy(idx)}
                  className="text-red-500 hover:text-red-700"
                >
                  &#10005; {/* Cross icon */}
                </button>
            </div>
          ))}
        
          <button type="button" onClick={addAllergy} className="mt-2 px-4 py-2 bg-green-500 text-white rounded-lg col-span-2 sm:w-[25%]">
            Add Allergy
          </button>
          
           {/* Lifestyle and Habits */}
           <h3 className="col-span-2 text-lg font-semibold">Lifestyle and Habits:</h3>
           <div className="col-span-2 grid grid-cols-1 sm:grid-cols-3 gap-2">
          <div>
            <label className="block text-gray-600 mb-1">Smoking Status:</label>
            <select
              value={formData?.lifestyleAndHabits?.smokingStatus}
              onChange={(e) => handleNestedPropertyChange(e, 'lifestyleAndHabits', 'smokingStatus')}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>Select Smoking Status</option>
              {['Current', 'Former', 'Never'].map((status) => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-600 mb-1">Alcohol Use:</label>
            <select
              value={formData?.lifestyleAndHabits?.alcoholUse}
              onChange={(e) => handleNestedPropertyChange(e, 'lifestyleAndHabits', 'alcoholUse')}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>Select Alcohol Use</option>
              {['No', 'Moderate', 'Habitual'].map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-600 mb-1">Tobacco / Gutka(Kharra) :</label>
            <input
              value={formData?.lifestyleAndHabits?.tobacco}
              onChange={(e) => handleNestedPropertyChange(e, 'lifestyleAndHabits', 'tobacco')}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />  
          </div>
          </div>

          {/* Past Dental History */}
          <div className="col-span-2">
            <label className="block text-gray-600 mb-1">Past Dental History:</label>
            <textarea
              name="pastDentalHistory"
              value={formData.pastDentalHistory}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          {/* Notes */}
          <div className="col-span-2">
            <label className="block text-gray-600 mb-1">Notes:</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
        </section>

{/* Submit Button */}
<button
            type="submit"
            disabled={!isModified}
            className={`mt-4 px-4 py-2 rounded-lg ${
              isModified ? 'bg-blue-500 text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'
            }`}
          >
            Save Changes
          </button>

          {error && <p className="text-red-500">{error}</p>}
          {success && <p className="text-green-500">{success}</p>}
        </form>
      </div>
    );
};

export default MedicalProfile;