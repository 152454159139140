
import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import Spinner from '../../Spinner';

const Checkup = () => {
    const [opdData, setOpdData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // State to manage which tiles are expanded
    const [expandedTiles, setExpandedTiles] = useState({});

    useEffect(() => {
        const fetchOpdData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/opd`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setOpdData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching OPD data:', error);
                setError(true);
                setLoading(false);
            }
        };

        fetchOpdData();
    }, []);

    const toggleTile = (id) => {
        setExpandedTiles((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };
    const handleCheckup = (id) => {
        // Handle the checkup action, e.g., navigate to checkup page
        console.log(`Checkup for OPD ID: ${id}`);
    };

    const handleEdit = (id) => {
        // Handle the edit action, e.g., open edit form
        console.log(`Edit OPD ID: ${id}`);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-50">
                <Spinner />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-50">
                <div className="text-red-500 text-xl">Failed to load data.</div>
            </div>
        );
    }

    // Animation variants for the tile expansion
    const variants = {
        collapsed: { height: 0, opacity: 0, overflow: 'hidden' },
        expanded: { height: 'auto', opacity: 1 },
    };

    // Animation variants for the tile container
    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.05 },
        }),
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4">
            <div className="container mx-auto">
                <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">OPD List</h1>
                <div className="space-y-6">
                    {opdData.map((opd, index) => (
                        <motion.div
                            key={opd._id}
                            className="bg-white border border-gray-200 rounded-lg shadow-sm"
                            initial="hidden"
                            animate="visible"
                            custom={index}
                            variants={containerVariants}
                        >
                            {/* Header */}
                            <div
                                className="flex justify-between items-center p-4 cursor-pointer"
                                onClick={() => toggleTile(opd._id)}
                            >
                                <div>
                                    <p className="text-xl font-semibold text-gray-800">
                                        OPD Number: <span className="text-blue-600">{opd.opdNumber}</span>
                                    </p>
                                    <p className="text-md text-gray-600">
                                        <span className="font-medium">Name:</span> {opd.userDetails.name}
                                    </p>
                                    <p className="text-md text-gray-600">
                                        <span className="font-medium">Age:</span> {opd.age}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Created At: {new Date(opd.createdAt).toLocaleString()}
                                    </p>
                                </div>
                                <div>
                                    {expandedTiles[opd._id] ? (
                                        <FaChevronUp className="h-6 w-6 text-gray-600" />
                                    ) : (
                                        <FaChevronDown className="h-6 w-6 text-gray-600" />
                                    )}
                                </div>
                            </div>

                            {/* Expanded Content with Animation */}
                            <AnimatePresence>
                                {expandedTiles[opd._id] && (
                                    <motion.div
                                        className="px-4 pb-4"
                                        initial="collapsed"
                                        animate="expanded"
                                        exit="collapsed"
                                        variants={variants}
                                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                                    >
                                        <div className="mt-4 border-t pt-4">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                {/* Patient Details */}
                                                <div>
                                                    <h2 className="text-lg font-semibold mb-2 text-gray-700">Patient Details</h2>
                                                    <div className="space-y-1 text-gray-600">
                                                        <p>
                                                            <span className="font-medium">Email:</span> {opd.user.email}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Contact:</span> {opd.userDetails.contact.mobile}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Address:</span> {opd.userDetails.contact.address}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Date of Birth:</span>{' '}
                                                            {new Date(opd.userDetails.dateOfBirth).toLocaleDateString()}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Gender:</span> {opd.userDetails.gender}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* Emergency Contact */}
                                                <div className="mt-6">
                                                    <h2 className="text-lg font-semibold mb-2 text-gray-700">Emergency Contact</h2>
                                                    <div className="space-y-1 text-gray-600">
                                                        <p>
                                                            <span className="font-medium">Name:</span> {opd.userDetails.emergencyContact.name}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Relationship:</span>{' '}
                                                            {opd.userDetails.emergencyContact.relationship}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Phone:</span> {opd.userDetails.emergencyContact.phone}
                                                        </p>
                                                    </div>
                                                </div>

                                                
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                {/* Medical Details */}
                                                <div>
                                                    <h2 className="text-lg font-semibold mb-2 text-gray-700">Medical Details</h2>
                                                    <div className="space-y-1 text-gray-600">
                                                        <p>
                                                            <span className="font-medium">Blood Group:</span> {opd.medicalDetails.bloodGroup}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Medical History:</span>{' '}
                                                            {opd.medicalDetails.medicalHistory}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Checkup Info:</span> {opd.checkupInfo}
                                                        </p>
                                                        <div>
                                                            <span className="font-medium">Lifestyle & Habits:</span>
                                                            <ul className="list-disc list-inside ml-4">
                                                                <li>
                                                                    Smoking Status: {opd.medicalDetails.lifestyleAndHabits.smokingStatus}
                                                                </li>
                                                                <li>
                                                                    Alcohol Use: {opd.medicalDetails.lifestyleAndHabits.alcoholUse}
                                                                </li>
                                                                <li>
                                                                    Tobacco: {opd.medicalDetails.lifestyleAndHabits.tobacco}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Additional Details */}
                                                <div className="mt-6">
                                                    <h2 className="text-lg font-semibold mb-2 text-gray-700">
                                                        Additional Information
                                                    </h2>
                                                    <div className="space-y-2 text-gray-600">
                                                        <div>
                                                            <span className="font-medium">Medications:</span>
                                                            {opd.medicalDetails.medications && opd.medicalDetails.medications.length > 0 ? (
                                                                <ul className="list-disc list-inside ml-4">
                                                                    {opd.medicalDetails.medications.map((med, idx) => (
                                                                        <li key={idx}>
                                                                            {med.name} - {med.dosage} - {med.frequency}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p className="ml-4">No medications listed.</p>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <span className="font-medium">Allergies:</span>
                                                            {opd.medicalDetails.allergies && opd.medicalDetails.allergies.length > 0 ? (
                                                                <ul className="list-disc list-inside ml-4">
                                                                    {opd.medicalDetails.allergies.map((allergy, idx) => (
                                                                        <li key={idx}>
                                                                            {allergy.allergen} - {allergy.reaction} ({allergy.severity})
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p className="ml-4">No allergies listed.</p>
                                                            )}
                                                        </div>
                                                        <p>
                                                            <span className="font-medium">Past Dental History:</span>{' '}
                                                            {opd.medicalDetails.pastDentalHistory || 'Not provided'}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Notes:</span>{' '}
                                                            {opd.medicalDetails.notes || 'No additional notes.'}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* Emergency Contact
                                                <div className="mt-6">
                                                    <h2 className="text-lg font-semibold mb-2 text-gray-700">Emergency Contact</h2>
                                                    <div className="space-y-1 text-gray-600">
                                                        <p>
                                                            <span className="font-medium">Name:</span> {opd.userDetails.emergencyContact.name}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Relationship:</span>{' '}
                                                            {opd.userDetails.emergencyContact.relationship}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Phone:</span> {opd.userDetails.emergencyContact.phone}
                                                        </p>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                <button
                                                    className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 focus:outline-none"
                                                    onClick={() => handleCheckup(opd._id)}
                                                >
                                                    Checkup
                                                </button>
                                                <button
                                                    className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 focus:outline-none"
                                                    onClick={() => handleEdit(opd._id)}
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Checkup;
