import React, { useState, useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';

const PersonalProfile = () => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: '',
    dateOfBirth: '',
    gender: '',
    contact: { mobile: '', email: '', address: '' },
    emergencyContact: { name: '', relationship: '', phone: '' }
  });
  const [originalData, setOriginalData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  // Fetch data from the server on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/details/${user.userDetails}`);
        const formattedData = {
          ...response.data,
          dateOfBirth: response.data.dateOfBirth ? new Date(response.data.dateOfBirth).toISOString().split('T')[0] : ''
        };

        setFormData(formattedData);
        setOriginalData(formattedData); // Keep original data to check for changes
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchData();
  }, [user.id]);

  // Detect changes in formData to enable the submit button
  useEffect(() => {
    if (originalData) {
      setHasChanges(JSON.stringify(formData) !== JSON.stringify(originalData));
    }
  }, [formData, originalData]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split('.');

    // Handle relationship field to set as 'Other' if not predefined

    setFormData((prevData) => ({
      ...prevData,
      ...(childKey
        ? { [parentKey]: { ...prevData[parentKey], [childKey]: value } }
        : { [name]: value }),
    }));
  };

  // Submit only if there are changes
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!hasChanges) {
      alert("No changes detected.");
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/details/${user.userDetails}`, formData);
      setOriginalData(formData); // Update originalData to new state after successful save
      setHasChanges(false);
      console.log("User details updated:", response.data);
      alert("User details updated successfully.");
    } catch (error) {
      console.error("Error updating user details:", error);
      alert("Failed to update user details.");
    }
  };

  if (!formData) return <p>Loading...</p>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      className="p-6 bg-white rounded-lg shadow-md  mx-auto mt-8"
    >
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Personal Details</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        {/* Personal Details */}
        <section className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
          <div>
            <label className="block text-gray-600 mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Date of Birth:</label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Gender:</label>
            <select
              name="gender"
              value={formData.gender || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          {/* Contact Information */}
          <div>
            <label className="block text-gray-600 mb-1">Mobile:</label>
            <input
              type="tel"
              name="contact.mobile"
              value={formData.contact?.mobile || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Email:</label>
            <input
              type="email"
              name="contact.email"
              value={formData.contact?.email || ''}
              onChange={handleChange}
              readOnly // This makes the email input read-only
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Address:</label>
            <input
              type="text"
              name="contact.address"
              value={formData.contact?.address || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          {/* Emergency Contact */}
          <div>
            <label className="block text-gray-600 mb-1">Emergency Contact Name:</label>
            <input
              type="text"
              name="emergencyContact.name"
              value={formData.emergencyContact?.name || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Phone:</label>
            <input
              type="tel"
              name="emergencyContact.phone"
              value={formData.emergencyContact?.phone || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          {/* Emergency Contact Relationship */}
          <div className="mb-4">
            <label className="block font-semibold mb-1">Relationship:</label>
            <select
              name="emergencyContact.relationship"
              value={formData.emergencyContact?.relationship || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            >
              <option value="">Select Relationship</option>
              <option value="Parent">Parent</option>
              <option value="Sibling">Sibling</option>
              <option value="Spouse">Spouse</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {formData.emergencyContact?.relationship === 'Other' && (
            <div className="mb-4">
              <label className="block font-semibold mb-1">Specify Relationship:</label>
              <input
                type="text"
                name="emergencyContact.relationshipOther"
                value={formData.emergencyContact?.relationshipOther || ''}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
          )}
        </section>

        <button
          type="submit"
          disabled={!hasChanges}
          className={`mt-4 px-4 py-2 rounded-lg ${hasChanges ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}`}
        >
          Save Changes
        </button>
      </form>

    </motion.div>
  );
};

export default PersonalProfile;
