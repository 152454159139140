import React from 'react'

const MedicalDetails = () => {
  return (
    <div>
      detaisl
    </div>
  )
}

export default MedicalDetails
