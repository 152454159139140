import React from 'react'

function ReceptionistDashboard() {
  return (
    <div>
      receptionist dashboard
    </div>
  )
}

export default ReceptionistDashboard
