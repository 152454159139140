import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/AuthContext';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Profile from './components/Dashboards/PatientDashboard/Profile';
import MyAppointment from './components/Dashboards/PatientDashboard/MyAppointment';
import MedicalDetails from './components/Dashboards/PatientDashboard/MedicalDetails';
import PatientDashboard from './components/Dashboards/PatientDashboard';
import Error from './components/Error';
import ReceptionistDashboard from './components/Dashboards/ReceptionistDashboard';
import AllAppointment from './components/Dashboards/ReceptionistDashboard/AllAppointment';
import OPDForm from './components/Dashboards/ReceptionistDashboard/OPDForm';
import { AnimatePresence } from 'framer-motion';
import DoctorDashboard from './components/Dashboards/DoctorDasboard';
import Checkup from './components/Dashboards/DoctorDasboard/Checkup';
import ForgotPassword from './components/ForgotPassword';


const App = () => {
  const { user, logout } = useContext(AuthContext); // This now works because AuthProvider wraps it
  
   
  return (
    <Router>
     <AnimatePresence >
      <Routes  >
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route element={<Dashboard />}>
          

           {user?.role === "patient"  && (
              <>
                <Route path="/dashboard" element={<PatientDashboard />} />
                <Route path="/dashboard/profile" element={<Profile />} />
                <Route path="/dashboard/appointment" element={<MyAppointment />} />
                <Route path="/dashboard/medical-details" element={<MedicalDetails />} />
              </>
            )}
            {user?.role === "receptionist"  && (
              <>
                <Route path="/dashboard" element={<ReceptionistDashboard />} />
                <Route path="/dashboard/appointment" element={<AllAppointment />} />
                <Route path="/dashboard/opd-form" element={<OPDForm />} />
              </>
            )}
            {user?.role === "doctor"  && (
              <>
                <Route path="/dashboard" element={<DoctorDashboard />} />
                <Route path="/dashboard/checkup" element={<Checkup />} />
                <Route path="/dashboard/appointment" element={<AllAppointment />} />
                <Route path="/dashboard/opd-form" element={<OPDForm />} />
              </>
            )}
            <Route path="*" element={<Error/>} />


        </Route>
      </Routes>
      </AnimatePresence>
    </Router>
  );
};

const RootApp = () => (
  <AuthProvider> {/* Move AuthProvider here to wrap the entire App */}
    <App />
  </AuthProvider>
);

export default RootApp;
