import React, { createContext, useState, useEffect } from 'react';
import  { jwtDecode } from 'jwt-decode';  // Corrected import for jwt-decode

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // To show loading until we verify the token

  // Check for token and user data in localStorage when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (token && storedUser) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds

        // Check if token is expired
        if (decodedToken.exp < currentTime) {
          logout(); // Token is expired, log out the user
        } else {
          setUser(JSON.parse(storedUser)); // Rehydrate user state if token is valid
        }
      } catch (error) {
        console.error("Invalid token", error);
        logout(); // Logout in case of invalid token
      }
    }

    setLoading(false); // Set loading to false after the check is complete
  }, []);

  const login = (token, userData) => {
    localStorage.setItem('token', token);

    try {
      const decodedToken = jwtDecode(token);
      console.log("Decoded Token:", decodedToken);

      localStorage.setItem('user', JSON.stringify(userData)); // Store user data
      setUser(userData);
    } catch (error) {
      console.error("Invalid token provided during login", error);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>; // Render a loading state until token is checked
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
