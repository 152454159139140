import React from 'react';
import { motion } from 'framer-motion';
import MedicalProfile from './MedicalProfile';
import PersonalProfile from './PersonalProfile';

const Profile = () => {
 
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
    >
      <PersonalProfile/>
      <MedicalProfile/>
    </motion.div>
  );
};

export default Profile;
